<template>
  <promote />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Promote from './index.vue'

export default defineComponent({
  name: 'App',
  components: {
    Promote
  }
})
</script>
