<template>
  <div class="rate">
    <div class="rate__under">
      <yb-svg-star
        v-for="i in 5"
        :key="i"
        :width="'12px'"
        :height="'12px'"
        :colors="['#ABABAB']"
        class="rate--svg"
      />

      <slot></slot>
    </div>
    <div class="rate__up">
      <yb-svg-star
        class="rate--svg"
        :width="'12px'"
        :height="'12px'"
        :colors="['#FFCF03']"
        v-for="i in stars"
        :key="i"
      />
      <yb-svg-star-half v-if="halfStar" class="rate--svg" :width="'12px'" :height="'12px'" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@include b(rate) {
  position: relative;

  @include e(up) {
    position: absolute;
    top: 0;
    left: 0;
  }

  @include m(svg) {
    margin: 0 1px;
  }
}
</style>

<script lang="ts">
import { reactive, Ref, toRefs } from 'vue'

interface IRateState {
  stars: Ref<number>;
  halfStar: Ref<boolean>;
}

export default {
  props: {
    count: {
      type: Number,
      required: true
    },
    total: {
      type: Number,
      default: 10
    }
  },
  setup (props: Readonly<{ total: number; count: number } >): IRateState {
    const state = reactive({
      stars: 0,
      halfStar: false
    })
    const scale = props.total / 5
    const realCount = props.count / scale
    state.halfStar = realCount % 2 !== 0 && props.count !== props.total

    state.stars = parseInt(realCount + '')

    return {
      ...toRefs(state)
    }
  }
}
</script>
