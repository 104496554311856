<template>
    <yb-card mt20 class="giftInfo-card" v-if="giftList.length > 0">
        <div class="block-title">
            <div class="block-title__left">
                <yb-svg-gift :width="'24px'" :height="'24px'" />
                <span class="block-title__left--text">礼包</span>
            </div>
            <div class="block-title__right" fr @click="onClick">
                <span class="block-title__right--text">共{{giftInfo.noReviceCount}}份礼包</span>
                <yb-svg-arrow :width="'14px'" :height="'14px'" class="block-title__right--svg" />
            </div>
        </div>
        <template v-if="giftList.length > 0">
            <div class="giftInfo-item" :key="item.giftId" v-for="(item) in giftList">
                <div class="giftInfo-item__left">
                    <span class="giftInfo-item__left--name">{{item.giftName}}</span>
                    <div class="giftInfo-item__left--content" v-html="item.giftInfo"></div>
                </div>
                <div class="giftInfo-item__right" @click="onClick">
                    <span class="giftInfo-item__right--button">领取</span>
                </div>
            </div>
        </template>
    </yb-card>
</template>

<script lang='ts'>
import { IGift, IGiftList } from '@/api/types/landingPage'
import { computed, PropType, Ref } from 'vue'
import { OpenAppEnum } from '@/views/components/types'

interface IWbGiftInfoState {
  onClick: () => void;
  giftList: Ref<IGift[]>
}

export default {
  props: {
    giftInfo: {
      type: Object as PropType<IGiftList>,
      required: true
    },
    handleOpenApp: {
      type: Function as PropType<(position: OpenAppEnum) => void>,
      required: true
    }
  },
  setup (
    props: Readonly<{
      giftInfo: IGiftList
      handleOpenApp: (position: OpenAppEnum) => void
    }>
  ): IWbGiftInfoState {
    const onClick = () => {
      props.handleOpenApp(OpenAppEnum.GIFT)
    }

    const giftList = computed<IGift[]>(() => {
      const list = [...props.giftInfo.giftList]
      return list
    })

    return {
      giftList,
      onClick
    }
  }
}
</script>
<style lang='scss' scoped>
.giftInfo-card {
  padding-bottom: 40px;
}
.block-title__right--text {
  font-size: 24px;
  font-weight: 400;
  color: #ababab;
  margin-right: 15px;
}
.block-title__right--svg {
  vertical-align: middle;
}
@include b(giftInfo-item) {
  padding: 20px 8px 30px 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px;

  @include e(left) {
    display: flex;
    flex-direction: column;
    @include m(name) {
      font-size: 30px;
      font-weight: 500;
      color: #272727;
      margin-bottom: 10px;
    }
    @include m(content) {
      font-size: 24px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #727272;
    }
  }

  @include e(right) {
    display: flex;
    align-items: center;

    @include m(button) {
      cursor: pointer;
      display: block;
      width: 120px;
      height: 50px;
      @include gradient(left, #FF9579, #FF737D, #FF737D);
      border-radius: 28px;
      font-size: 24px;
      font-weight: 500;
      color: #ffffff;
      text-align: center;
      line-height: 50px;
    }
  }

  &:not(:last-child) {
    border-bottom: 1px solid #ebebeb;
  }
}
</style>
