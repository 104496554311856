
import { ICoupon, ICouponInfo } from '@/api/types/landingPage'
import { computed, PropType, Ref } from 'vue'
import { OpenAppEnum } from '@/views/components/types'
import { parseTime } from '@/utils/index'

interface IWbCouponInfoState {
  couponList: Ref<ICoupon[]>
  onClick: () => void
  getDrawDetails: (item: ICoupon) => void
}

export default {
  props: {
    couponInfo: {
      type: Object as PropType<ICouponInfo>,
      required: true
    },
    handleOpenApp: {
      type: Function as PropType<(position: OpenAppEnum) => void>,
      required: true
    }
  },
  setup (
    props: Readonly<{
      couponInfo: ICouponInfo
      handleOpenApp: (position: OpenAppEnum) => void
    }>
  ): IWbCouponInfoState {
    const couponList = computed<ICoupon[]>(() => {
      const list = [...props.couponInfo.couponList]
      if (list.length > 10) {
        return list.splice(9)
      }
      return list
    })
    const onClick = () => {
      props.handleOpenApp(OpenAppEnum.COUPON)
    }

    const getDrawDetails = (item: ICoupon) => {
      let text = ''
      if (item.timeType === 0) {
        const startTime = parseTime(item.startTime, '{y}-{m}-{d}')
        const endTime = parseTime(item.endTime, '{y}-{m}-{d}')
        text = `有效时间:${startTime}至${endTime}, 请及时使用`
        return text
      } else if (item.timeType === 1) {
        text = `领取后${item.calculateDay}天有效,请及时使用`
        return text
      }
    }

    return {
      couponList,
      onClick,
      getDrawDetails
    }
  }
}
