<template>
  <yb-card mt20>
    <div class="block-title">
      <div class="block-title--left">
        <yb-svg-game :width="'24px'" :height="'24px'" />
        <span class="block-title__left--text">相关游戏</span>
      </div>
    </div>
    <div class="game-list">
      <div class="game-list__item"
        v-for="(game, index) in gameList"
        :key="game.gameId + index"
        @click="onClick"
      >
        <div class="game-list__item--top">
          <div class="top-content">
            <img class="top-img" :src="game.gameIcon" alt="">
          </div>
        </div>
        <p class="game-list__item--name">{{game.gameName}}</p>
      </div>
    </div>
  </yb-card>
</template>

<script lang="ts">
import { IRecommendGame } from '@/api/types/landingPage'
import { PropType } from 'vue'
import { OpenAppEnum } from '../types'
import { Image as VanImage } from 'vant'

interface IOthersState {
  onClick: () => void;
}

export default {
  name: 'other-game',
  components: {
    [VanImage.name]: VanImage
  },
  props: {
    gameList: {
      type: Array as PropType<IRecommendGame[]>,
      required: true
    },
    handleOpenApp: {
      type: Function as PropType<(position: OpenAppEnum) => void>,
      required: true
    }
  },
  setup (
    props: Readonly<{
      gameList: IRecommendGame[];
      handleOpenApp: (position: OpenAppEnum) => void;
    }>
  ): IOthersState {
    const onClick = () => {
      props.handleOpenApp(OpenAppEnum.GAME)
    }

    return {
      onClick
    }
  }
}
</script>

<style lang="scss" scoped>
@include b(game-list) {
  margin-top: 28px;
  &:after {
    content: '';
    display: block;
    height: 0;
    clear: both;
  }

  @include e(item) {
    width: 22%;
    width: 22%;
    margin-right: 4%;
    margin-top: 4%;
    float: left;

    &:nth-child(1), &:nth-child(2), &:nth-child(3), &:nth-child(4) {
      margin-top: 0;
    }

    &:nth-child(4n) {
      margin-right: 0;
    }

    @include m(top) {
      position: relative;
      padding-top: 100%;
      height: 0;

      .top-content {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        box-sizing: border-box;
        overflow: hidden;
        border-radius: 24px;
        // background-image: url('../../../assets/placehold_horizontal.png');
        background-color: #ebebeb;
        // background-size: cover;
        // background-position-x: center;
      }

      .top-img {
        border-radius: 24px;
        // height: 150px;
      }
    }

    @include m(name) {
      margin-top: 3px;
      @include box-clamp(1);
      font-size: 24px;
      color: #24273F;
      text-align: center;
    }
  }
}
</style>
