
import { IGameInfo } from '@/api/types/landingPage'
import { onMounted, PropType, reactive, ref, Ref, toRefs } from 'vue'

interface IWbWelfareInfoState {
  welfareContentRef: Ref<HTMLElement | undefined>
  expande: Ref<boolean>
  ifOver: Ref<boolean>
  expandeClick: () => void
}

export default {
  props: {
    gameinfo: {
      type: Object as PropType<IGameInfo>,
      required: true
    }
  },
  setup (): IWbWelfareInfoState {
    const state = reactive({
      expande: false,
      ifOver: false
    })
    const welfareContentRef = ref<HTMLElement>()

    const expandeClick = () => {
      state.expande = !state.expande
    }

    onMounted(() => {
      if (welfareContentRef.value) {
        state.ifOver =
          welfareContentRef.value.offsetHeight <
          welfareContentRef.value.scrollHeight
      }
    })

    return {
      ...toRefs(state),
      expandeClick,
      welfareContentRef
    }
  }
}
