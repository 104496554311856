
import YbRate from '@/components/Rate.vue'
import { computed, PropType, ref, Ref } from 'vue'
import { IGameInfo } from '@/api/types/landingPage'
import { NoticeBar, Swipe, SwipeItem } from 'vant'
import noticeImg from '@/assets/notice.png'

interface IWelfareTags {
  type: number
  content: string
}

interface IWbGameInfoState {
  welfareTags: Ref<IWelfareTags[]>
  tagCount: Ref<number>
  tagDynamicStyle: (type: number) => void
  noticeImg: string
  gameDynamics: Ref<string[] | string>
}
export default {
  props: {
    gameinfo: {
      type: Object as PropType<IGameInfo>,
      required: true
    },
    trumpetMessageList: {
      type: [Array, String] as PropType<string[] | string>,
      required: true
    }
  },
  components: {
    [NoticeBar.name]: NoticeBar,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    YbRate
  },
  setup (
    props: Readonly<{
      gameinfo: IGameInfo
      trumpetMessageList: string[] | string
    }>
  ): IWbGameInfoState {
    const tagCount = ref(0)

    const welfareTags = computed<IWelfareTags[]>(() => {
      if (props.gameinfo.welfareTags) {
        const Tags = JSON.parse(props.gameinfo.welfareTags)
        return Tags
      }
      return []
    })

    const gameDynamics = computed<string[] | string>(() => {
      if (props.trumpetMessageList.length > 0) {
        return props.trumpetMessageList
      }
      return []
    })

    const tagDynamicStyle = (type: number) => {
      const style = {
        background: 'rgba(255, 252, 241, 1)',
        color: '#F8CB62',
        border: '1px solid #FFF2C9'
      }
      switch (type) {
        case 0:
          style.background = 'rgba(255, 245, 243, 1)'
          style.color = '#FF7D61'
          style.border = '1px solid #FFE0DA'
          break
        case 1:
          style.background = 'rgba(255, 248, 241, 1)'
          style.color = '#FFA857'
          style.border = '1px solid #FFE3C9'
          break
        case 2:
          style.background = 'rgba(241, 255, 247, 1)'
          style.color = '#60D390'
          style.border = '1px solid #C9F4DA'
          break

        default:
          break
      }
      return style
    }

    return {
      tagCount,
      welfareTags,
      tagDynamicStyle,
      noticeImg,
      gameDynamics
    }
  }
}
