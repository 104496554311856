<template>
  <yb-card class="media-card">
    <div class="media">
      <template
        v-for="(img, index) in imgs"
        :key="img.url"
      >
        <div
          :class="{
            'media__item': true,
            'media__video': true,
            'media__horizontal': !isAllVertical && img.position === 'horizontal',
            'media__vertical': !isAllVertical && img.position === 'vertical',
            'media__all-vertical': isAllVertical,
          }"
          v-if="hadVideo && index === 0">
          <img :src="img.url" @click="showPopup = true" crossorigin="anonymous">
          <yb-svg-play class="yb-icon" width="40px" height="40px" :colors="['#ffffff', '#000000']" @click="showPopup = true"></yb-svg-play>
        </div>
        <div
          :class="{
            'media__item': true,
            'media__horizontal': !isAllVertical && img.position === 'horizontal',
            'media__vertical': !isAllVertical && img.position === 'vertical',
            'media__all-vertical': isAllVertical,
          }"
          @click="handlePreviewImage(index)"
          v-else
        >
          <img :src="img.url" crossorigin="anonymous">
        </div>
      </template>
    </div>

     <van-popup v-model:show="showPopup" teleport="body"
        @closed="qrcodeDateUrl = ''"
        class="yb-video-popup"
      >
        <div class="video-popup">
          <video
            v-if="showPopup"
            :src="gameinfo.videoData.videoUrl"
            controls="controls" loop="loop"
            :poster="gameinfo.videoData.imgUrl"
            :style="`${gameinfo.videoData.height > gameinfo.videoData.width ? 'height' : '70vh'}`"
            autoplay
          >
          </video>
        </div>
      </van-popup>
  </yb-card>
</template>

<script lang="ts">
import { PropType, ref, Ref, onMounted } from 'vue'
import { IGameInfo } from '@/api/types/landingPage'
import { ImagePreview, Popup, Icon } from 'vant'
import 'vant/lib/image-preview/style'
import 'vant/lib/icon/style'
import videoBgImg from '../../../assets/video_bg.png'

type IPosition = 'horizontal' | 'vertical'

interface IImage {
  url: string;
  position: IPosition;
}

interface IMediaState {
  handlePreviewImage: (index: number) => void;
  showPopup: Ref<boolean>;
  isAllVertical: Ref<boolean>;
  imgs: Ref<IImage[]>;
  hadVideo: Ref<boolean>;
}

export default {
  props: {
    gameinfo: {
      type: Object as PropType<IGameInfo>,
      required: true
    }
  },
  components: {
    [Popup.name]: Popup,
    [Icon.name]: Icon
  },
  setup (
    props: Readonly<{
      gameinfo: IGameInfo;
    }>
  ): IMediaState {
    const showPopup = ref(false)
    const hadVideo = ref(false)
    const isAllVertical = ref(true)
    const imgs = ref<IImage[]>([])
    let imgList: string[] = []

    const handlePreviewImage = (index: number): void => {
      const arr = [...imgList]
      if (hadVideo.value) {
        arr.shift()
        index = index - 1
      }
      ImagePreview({
        images: arr,
        closeable: true,
        startPosition: index
      })
    }

    const getImgPosition = (arr: string[]) => {
      arr.forEach((img, index) => {
        const image = new Image()
        image.crossOrigin = 'anonymous'
        image.onload = () => {
          if (image.width >= image.height) {
            isAllVertical.value = false
          }
          imgs.value.splice(index, 0, {
            url: img,
            position: image.width >= image.height ? 'horizontal' : 'vertical'
          })
        }
        image.onerror = (e) => {
          console.error(e)
        }
        image.src = img
      })
    }

    onMounted(() => {
      imgList = []
      if (props.gameinfo.videoData && props.gameinfo.videoData.videoUrl) {
        hadVideo.value = true

        if (props.gameinfo.videoData.imgUrl) {
          imgList.push(props.gameinfo.videoData.imgUrl)
        } else if (props.gameinfo.coverUrl) {
          imgList.push(props.gameinfo.coverUrl)
        } else {
          imgList.push(videoBgImg)
        }
      }

      props.gameinfo.pics.forEach(pic => {
        imgList.push(pic.imgUrl)
      })

      getImgPosition(imgList)
    })

    return {
      handlePreviewImage,
      showPopup,
      isAllVertical,
      imgs,
      hadVideo
    }
  }
}
</script>

<style lang="scss" scoped>
@include b(media) {
  display:inline;
  white-space:nowrap;
  background-color: $background-color-secondary;

  @include e(item) {
    border: 1px solid rgba(0, 0, 0, .005);
    border-radius: 20px;
    overflow: hidden;
    box-sizing: border-box;
    display: inline-block;
    position: relative;

    &:not(:last-child) {
      margin-right: 20px;
    }

    img {
      height: 100%;
      width: 100%;
      border-radius: 10px;
      vertical-align: top;
      object-fit: cover;
    }

    .yb-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  @include e(all-vertical) {
    height: 500px;
    width: 280px;
    background-image: url('../../../assets/placehold_vertical.png');
    background-size: cover;
  }

  @include e(vertical) {
    height: 380px;
    width: 215px;
    background-image: url('../../../assets/placehold_vertical.png');
    background-size: cover;
  }

  @include e(horizontal) {
    height: 380px;
    width: 675px;
    background-image: url('../../../assets/placehold_horizontal.png');
    background-size: cover;
  }

  @include e(video) {
    img {
      width: 100%;
    }
  }
}
@include b(video-popup) {
  video {
    width: 100vw;
    display: block;
    max-width: $max-window-width;
    max-height: 470px;
  }
}
</style>

<style>
  .yb-video-popup {
    background-color: transparent;
  }
</style>
