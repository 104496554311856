
import YbRate from '@/components/Rate.vue'
import { PropType } from 'vue'
import { IGameInfo } from '@/api/types/landingPage'

export default {
  props: {
    gameinfo: {
      type: Object as PropType<IGameInfo>,
      required: true
    }
  },
  components: {
    YbRate
  }
}
