<template>
    <yb-card mt20 class="couponInfo-card" v-if="couponList.length > 0">
        <div class="block-title">
            <div class="block-title__left">
                <yb-svg-coupon :width="'24px'" :height="'24px'" />
                <span class="block-title__left--text">代金券</span>
            </div>
            <div class="block-title__right" fr @click="onClick">
                <span class="block-title__right--text">共{{couponList.length}}张代金券</span>
                <yb-svg-arrow :width="'14px'" :height="'14px'" class="block-title__right--svg" />
            </div>
        </div>
        <div class="coupon-content" v-if="couponList.length > 0">
            <template v-if="couponList.length !== 1">
                <div
                    class="coupon-content__item"
                    v-for="(item, index) in couponList"
                    :key="item.couponTemplateId + index"
                    @click="onClick"
                >
                    <div class="coupon-content__item--left">
                        <span>
                            ¥
                            <span class="coupon-content__item--left-number">{{item.amount / 100}}</span>
                        </span>
                        <span
                            class="coupon-content__item--left-text"
                        >满{{item.useAmountThreshold / 100}}可用</span>
                    </div>
                    <div class="coupon-content__item--right">
                        <span>领取</span>
                    </div>
                </div>
            </template>
            <template v-if="couponList.length === 1">
                <div
                    class="coupon-content__onlyOne"
                    v-for="(item, index) in couponList"
                    :key="item.couponTemplateId + index"
                    @click="onClick"
                >
                    <div class="coupon-content__onlyOne--left">
                        <span>
                            ¥
                            <span
                                class="coupon-content__onlyOne--left-number"
                            >{{item.amount / 100}}</span>
                        </span>
                        <span
                            class="coupon-content__onlyOne--left-text"
                        >满{{item.useAmountThreshold / 100}}可用</span>
                    </div>
                    <div class="coupon-content__onlyOne--middle">
                        <span>{{item.couponTemplateName}}</span>
                        <span>{{getDrawDetails(item)}}</span>
                    </div>
                    <div class="coupon-content__onlyOne--right">
                        <span>领取</span>
                    </div>
                </div>
            </template>
        </div>
    </yb-card>
</template>

<script lang='ts'>
import { ICoupon, ICouponInfo } from '@/api/types/landingPage'
import { computed, PropType, Ref } from 'vue'
import { OpenAppEnum } from '@/views/components/types'
import { parseTime } from '@/utils/index'

interface IWbCouponInfoState {
  couponList: Ref<ICoupon[]>
  onClick: () => void
  getDrawDetails: (item: ICoupon) => void
}

export default {
  props: {
    couponInfo: {
      type: Object as PropType<ICouponInfo>,
      required: true
    },
    handleOpenApp: {
      type: Function as PropType<(position: OpenAppEnum) => void>,
      required: true
    }
  },
  setup (
    props: Readonly<{
      couponInfo: ICouponInfo
      handleOpenApp: (position: OpenAppEnum) => void
    }>
  ): IWbCouponInfoState {
    const couponList = computed<ICoupon[]>(() => {
      const list = [...props.couponInfo.couponList]
      if (list.length > 10) {
        return list.splice(9)
      }
      return list
    })
    const onClick = () => {
      props.handleOpenApp(OpenAppEnum.COUPON)
    }

    const getDrawDetails = (item: ICoupon) => {
      let text = ''
      if (item.timeType === 0) {
        const startTime = parseTime(item.startTime, '{y}-{m}-{d}')
        const endTime = parseTime(item.endTime, '{y}-{m}-{d}')
        text = `有效时间:${startTime}至${endTime}, 请及时使用`
        return text
      } else if (item.timeType === 1) {
        text = `领取后${item.calculateDay}天有效,请及时使用`
        return text
      }
    }

    return {
      couponList,
      onClick,
      getDrawDetails
    }
  }
}
</script>
<style lang='scss' scoped>
.couponInfo-card {
  padding-bottom: 40px;
}
.block-title__right--text {
  font-size: 24px;
  font-weight: 400;
  color: #ababab;
  margin-right: 15px;
}
.block-title__right--svg {
  vertical-align: middle;
}

@mixin com-left_text {
  cursor: pointer;
  width: auto;
  height: 36px;
  background: rgba(249, 97, 123, 1);
  border-radius: 18px;
  font-size: 24px;
  font-weight: 300;
  color: #ffffff;
  text-align: center;
  line-height: 36px;
  padding: 0px 8px;
}

@mixin com-right_span {
  cursor: pointer;
  width: 88px;
  height: 44px;
  background: rgba(255, 115, 138, 1);
  border-radius: 12px;
  font-size: 24px;
  font-weight: 500;
  color: #ffffff;
  text-align: center;
  line-height: 44px;
}

@mixin com-left_number {
  font-size: 55px;
  font-weight: 500;
  color: #ffffff;
}

@include b(coupon-content) {
  display: -webkit-box;
  white-space: nowrap;
  background-color: $background-color-secondary;
  overflow-x: scroll;
  padding: 25px 10px 5px 0;

  @include e(item) {
    height: 150px;
    width: 281px;
    overflow: hidden;
    margin-right: 20px;
    background-image: url(../../../assets/coupons_bg.png);
    background-size: cover;
    display: flex;
    flex-direction: row;
    justify-content: space-around;

    @include m(left) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 56%;
    }

    span:first-child {
      color: #ffffff;
    }

    @include m(left-number) {
      @include com-left_number;
    }

    @include m(left-text) {
      @include com-left_text;
    }

    @include m(right) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 44%;

      span {
        @include com-right_span;
      }
    }
  }

  @include e(onlyOne) {
    height: 150px;
    width: 100%;
    overflow: hidden;
    background: url(../../../assets/coupons_bg_long.png) no-repeat;
    background-size: 100% 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;

    @include m(left) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 20%;

      span:first-child {
        color: #ffffff;
      }
    }

    @include m(left-number) {
      @include com-left_number;
    }

    @include m(left-text) {
      @include com-left_text;
    }

    @include m(middle) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 55%;
      padding: 0 0 0 20px;

      span:first-child {
        font-size: 32px;
        font-weight: 500;
        color: #272727;
        margin-bottom: 5px;
      }

      span:nth-child(2) {
        overflow: hidden; //超出的文本隐藏
        text-overflow: ellipsis; //溢出用省略号显示
        white-space: nowrap; //溢出不换行
        font-size: 20px;
        font-weight: 400;
        color: #727272;
      }
    }

    @include m(right) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 15%;

      span {
        @include com-right_span;
      }
    }
  }
}
</style>
