
import { IGift, IGiftList } from '@/api/types/landingPage'
import { computed, PropType, Ref } from 'vue'
import { OpenAppEnum } from '@/views/components/types'

interface IWbGiftInfoState {
  onClick: () => void;
  giftList: Ref<IGift[]>
}

export default {
  props: {
    giftInfo: {
      type: Object as PropType<IGiftList>,
      required: true
    },
    handleOpenApp: {
      type: Function as PropType<(position: OpenAppEnum) => void>,
      required: true
    }
  },
  setup (
    props: Readonly<{
      giftInfo: IGiftList
      handleOpenApp: (position: OpenAppEnum) => void
    }>
  ): IWbGiftInfoState {
    const onClick = () => {
      props.handleOpenApp(OpenAppEnum.GIFT)
    }

    const giftList = computed<IGift[]>(() => {
      const list = [...props.giftInfo.giftList]
      return list
    })

    return {
      giftList,
      onClick
    }
  }
}
