import { createApp } from 'vue'
import App from './App.vue'
import YbUI from '../../components'
import webLog from '../../utils/webLog'

import 'normalize.css/normalize.css'
import '../../style/index.scss'
import 'vant/lib/index.css'
// import 'vant/lib/list/style'
// import 'vant/lib/toast/style'
// import 'vant/lib/popup/style'

webLog.append()

createApp(App).use(YbUI).mount('#promote')
