<template>
    <yb-card mt20>
        <div class="block-title">
            <div class="block-title--left">
                <yb-svg-chat :width="'24px'" :height="'24px'" />
                <span class="block-title__left--text">游戏简介</span>
            </div>
        </div>

        <div class="game-detail">
            <div class="detail-block">
                <div class="detail-block__title">{{gameinfo.recommdendReason}}</div>
                <div class="detail-block__desc">
                    <div
                        :class="{'detail-block__desc--hidden': !expande}"
                        ref="detailDescRef"
                    >{{gameinfo.introduce}}</div>
                    <div
                        class="detail-block__desc--moreBtn"
                        v-if="ifOver"
                        @click="expandeClick"
                    >{{expande ? '收起' : '展开'}}</div>
                </div>
            </div>

            <div class="detail-block" v-if="gameinfo.updateContent">
                <div class="detail-block__title">{{gameinfo.gameVersionName}}版本更新内容：</div>
                <div class="detail-block__desc">
                    <div
                        :class="{'detail-block__desc--hidden': !expande}"
                        ref="detailDescRef"
                    >{{gameinfo.updateContent}}</div>
                    <div
                        class="detail-block__desc--moreBtn"
                        v-if="ifOver"
                        @click="expandeClick"
                    >{{expande ? '收起' : '展开'}}</div>
                </div>
            </div>

            <div class="detail-block">
                <div class="detail-block__title">详细信息：</div>
                <div
                    class="detail-block__desc"
                    v-if="gameinfo.gameDeveloperName"
                >厂商：{{gameinfo.gameDeveloperName}}</div>
                <div class="detail-block__desc">版本：{{gameinfo.gameVersionName}}</div>
                <div
                    class="detail-block__desc"
                >游戏大小：{{(gameinfo.pkgSize / 1024 / 1024).toFixed(2)}} MB</div>
            </div>

            <div class="line" mt40></div>

            <div class="tags" @click="onClick">
                <div class="tags__button">
                    <template v-for="(tag, index) in gameinfo.tagVOS" :key="tag.labelTypeId">
                        <span
                            class="tags__button--tag"
                            v-if="index + 1 <= tagCount"
                        >{{tag.labelName}}</span>
                    </template>
                </div>
                <span class="tags__arrow">
                    <img class="tags__arrow--icon" src="../../../assets/arrow.png" alt />
                </span>
            </div>
        </div>
    </yb-card>
</template>

<script lang="ts">
import { onMounted, PropType, reactive, ref, Ref, toRefs } from 'vue'
import { IGameInfo } from '@/api/types/landingPage'
import { OpenAppEnum } from '../types'

interface IGameDetailState {
  tagCount: Ref<number>
  onClick: () => void
  expande: Ref<boolean>
  ifOver: Ref<boolean>
  expandeClick: () => void
  detailDescRef: Ref<HTMLElement | undefined>
}

export default {
  props: {
    gameinfo: {
      type: Object as PropType<IGameInfo>,
      required: true
    },
    handleOpenApp: {
      type: Function as PropType<(position: OpenAppEnum) => void>,
      required: true
    }
  },
  setup (
    props: Readonly<{
      gameinfo: IGameInfo
      handleOpenApp: (position: OpenAppEnum) => void
    }>
  ): IGameDetailState {
    const state = reactive({
      expande: false,
      ifOver: false
    })
    const tagCount = ref(0)
    const worldWidth = 14
    const padding = 20
    const margin = 10
    const offset = 55
    let totalWidth = 0
    const maxWidth = window.innerWidth - offset

    const detailDescRef = ref<HTMLElement>()

    props.gameinfo.tagVOS.forEach((tag) => {
      totalWidth += tag.labelName.length * worldWidth + margin + padding
      if (maxWidth < totalWidth) {
        return false
      }
      tagCount.value += 1
    })

    const onClick = () => {
      props.handleOpenApp(OpenAppEnum.TAGS)
    }

    const expandeClick = () => {
      state.expande = !state.expande
    }

    onMounted(() => {
      if (detailDescRef.value) {
        state.ifOver =
          detailDescRef.value.offsetHeight < detailDescRef.value.scrollHeight
      }
    })

    return {
      tagCount,
      onClick,
      ...toRefs(state),
      expandeClick,
      detailDescRef
    }
  }
}
</script>

<style lang="scss" scoped>
@include b(detail-block) {
  margin-top: 40px;

  @include e(title) {
    font-weight: bold;
    font-size: 30px;
    line-height: 2;
    color: $color-text-secondary;
  }

  @include e(desc) {
    color: $color-text-primary;
    font-size: 28px;
    line-height: 2;
    white-space: pre-wrap;

    @include m(hidden) {
      @include ellipsis(6);
    }

    @include m(moreBtn) {
      cursor: pointer;
      text-align: end;
      font-size: 28px;
      font-weight: 400;
      color: #ffa100;
      margin-top: 5px;
    }
  }
}

@include b(tags) {
  margin-top: 20px;
  padding: 20px 0;
  position: relative;

  @include e(button) {
    margin-right: 50px;
    overflow-x: scroll;
    white-space: nowrap;
    scrollbar-width: none;
    /* Firefox */
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none;
    }

    @include m(tag) {
      height: 48px;
      background-color: rgba(255, 196, 0, 0.2);
      color: #ffa100;
      font-size: 28px;
      padding: 5px 20px;
      border-radius: 24px;
      line-height: 48px;
      overflow-x: hidden;

      &:not(:last-child) {
        margin-right: 20px;
      }
    }
  }

  @include e(arrow) {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);

    @include m(icon) {
      width: 24px;
      // height: 24px;
    }
  }
}
</style>
