<template>
    <yb-layout class="app-layout">
        <!-- 头部 -->
        <b-header
            :headerLogo="headerLogo"
            :headerNameLogo="headerNameLogo"
            :handleOpenApp="handleOpenApp"
        ></b-header>

        <template v-if="!error">
            <yb-content class="app-content">
                <van-list
                    v-model:loading="loading"
                    :finished="finished"
                    @load="onListLoad"
                    :offset="30"
                    finished-text="没有更多了吖"
                    error-text="请求失败，点击重新加载"
                >
                    <!-- 视频or头图 -->
                    <p-media v-if="gameinfo" :gameinfo="gameinfo"></p-media>

                    <!-- 游戏信息-歪比版 -->
                    <p-gameinfo v-if="versionName === 'yb' && gameinfo" :gameinfo="gameinfo"></p-gameinfo>

                    <!-- 游戏信息-玩呗版 -->
                    <wb-game-info
                        v-if="versionName === 'wb' && gameinfo"
                        :gameinfo="gameinfo"
                        :trumpetMessageList="trumpetMessageList"
                    ></wb-game-info>
                    <!-- 代金券-玩呗版 -->
                    <wb-coupon-info
                        v-if="versionName === 'wb' && couponInfo"
                        :couponInfo="couponInfo"
                        :handleOpenApp="handleOpenApp"
                    ></wb-coupon-info>
                    <!-- 礼包内容-玩呗版 -->
                    <wb-gift-info
                        v-if="versionName === 'wb' && giftInfo"
                        :giftInfo="giftInfo"
                        :handleOpenApp="handleOpenApp"
                    ></wb-gift-info>
                    <!-- 福利活动-玩呗版 -->
                    <wb-welfare-info
                        v-if="versionName === 'wb' && gameinfo?.welfareInfo"
                        :gameinfo="gameinfo"
                    ></wb-welfare-info>

                    <!-- 游戏简介 -->
                    <p-game-detail
                        :handleOpenApp="handleOpenApp"
                        v-if="gameinfo"
                        :gameinfo="gameinfo"
                    ></p-game-detail>

                    <!-- 评论 -->
                    <p-comment
                        v-if="commentList.length > 0"
                        :commentList="commentList"
                        :handleOpenApp="handleOpenApp"
                    ></p-comment>

                    <!-- 相关游戏 -->
                    <p-others
                        v-if="recommendGameList.length > 0"
                        :gameList="recommendGameList"
                        :handleOpenApp="handleOpenApp"
                    ></p-others>
                </van-list>
            </yb-content>
            <div style="height: 54px;"></div>

            <b-footer
                v-if="gameinfo"
                :gameinfo="gameinfo"
                :handleOpenApp="handleOpenApp"
                :gameAlias="gameAlias"
            ></b-footer>
        </template>

        <b-error :handleOpenApp="handleOpenApp" v-else />

        <van-popup
            v-model:show="showPopup"
            round
            teleport="body"
            closeable
            close-icon-position="top-right"
            @closed="qrcodeDateUrl = ''"
        >
            <b-popup
                :popupTitle="popupTitle"
                :popupBoxBtnText="popupBoxBtnText"
                :popupBoxLogo="headerLogo"
                :popupBoxNameLogo="headerNameLogo"
                :gameinfo="gameinfo"
                :qrcodeDateUrl="qrcodeDateUrl"
                :popupText="popupText"
                :packageUrl="packageUrl"
                :isWeblog="true"
            ></b-popup>
        </van-popup>

        <div class="share" v-if="showShare" @click="showShare = false">
            <img fr src="../../assets/share.png" alt />
        </div>
    </yb-layout>
</template>

<script lang="ts">
import coms from './components'
import { BHeader, BPopup, BFooter, BError } from '@/views/components/index'
import { reactive, toRefs, Ref, ref, onMounted } from 'vue'
import { List, Popup, Toast } from 'vant'
import {
  getCommentList,
  getGameInfo,
  getRecommendGameList,
  getChannelURL,
  getTrumpetMessage,
  getGiftList,
  getCouponList
} from '../../api/request/landingPage'
import {
  IGameInfo,
  ICommentInfo,
  IRecommendGame,
  IGiftList,
  ICouponInfo
} from '@/api/types/landingPage'
import CallApp from 'callapp-lib'
import { copyText } from 'vue3-clipboard'
import { OpenAppEnum } from '@/views/components/types'
import UaParser from 'ua-parser-js'
import { toDataURL } from 'qrcode'
import queryString from 'query-string'
import webLog from '../../utils/webLog'
import detailsConfig from '@/config/details'
import {
  WbGameInfo,
  WbWelfareInfo,
  WbGiftInfo,
  WbCouponInfo
} from './wb-components'

const { logo, nameLogo, title, btnText } = detailsConfig
const versionName = process.env.VUE_APP_NAME || 'yb'

const { PMedia, PGameinfo, PGameDetail, PComment, POthers } = coms

interface IState {
  loading: Ref<boolean>
  finished: Ref<boolean>
  showShare: Ref<boolean>
  packageUrl: Ref<string>
  onListLoad: () => void
  gameinfo: Ref<IGameInfo | undefined>
  commentList: Ref<ICommentInfo[]>
  recommendGameList: Ref<IRecommendGame[]>
  gameAlias: string
  error: Ref<boolean>
  headerLogo: Ref<string>
  headerNameLogo: Ref<string>
  popupTitle: Ref<string>
  popupBoxBtnText: Ref<string>
  handleOpenApp: (position: OpenAppEnum) => void
  versionName: string
  trumpetMessageList: Ref<string[] | string>
  giftInfo: Ref<IGiftList | undefined>
  couponInfo: Ref<ICouponInfo | undefined>
}

export default {
  components: {
    [List.name]: List,
    [Popup.name]: Popup,
    BHeader,
    BPopup,
    BFooter,
    BError,
    PMedia,
    PGameinfo,
    PGameDetail,
    PComment,
    POthers,
    WbGameInfo,
    WbWelfareInfo,
    WbGiftInfo,
    WbCouponInfo
  },
  setup (): IState {
    const state = reactive({
      loading: false,
      finished: false,
      showShare: false,
      showPopup: false,
      qrcodeDateUrl: '',
      popupText: 'APP发现更多好玩新奇游戏',
      error: false,
      packageUrl: '',
      headerLogo: logo,
      headerNameLogo: nameLogo,
      popupTitle: title,
      popupBoxBtnText: btnText
    })

    const urlPath = window.location.pathname
    const urlPathArr = urlPath.split('/')
    const gameAlias = urlPathArr[urlPathArr.length - 1]
    const urlSearch = queryString.parse(location.search)
    const packageId = urlSearch.packageId ? (urlSearch.packageId as string) : ''
    let currentPage = 1
    // const gameAlias = 'i6824443'

    const gameinfo = ref<IGameInfo | undefined>()
    const commentList = ref<ICommentInfo[]>([])
    const recommendGameList = ref<IRecommendGame[]>([])
    let gameId = -1

    // 小喇叭消息
    const trumpetMessageList = ref<string[] | string>([])
    // 礼包
    const giftInfo = ref<IGiftList>()
    // 代金券
    const couponInfo = ref<ICouponInfo>()

    const onListLoad = () => {
      // 加载状态结束
      state.loading = true

      if (gameId !== -1) {
        currentPage = currentPage + 1
        if (currentPage > 10) {
          return
        }
        getRecommendGameList(gameId, currentPage).then(
          (res) => {
            if (res.data.games.length) {
              recommendGameList.value = recommendGameList.value.concat(
                res.data.games
              )
              if (recommendGameList.value.length >= 240) {
                state.finished = true
              }
            } else {
              state.finished = true
            }
            state.loading = false
          },
          () => {
            state.loading = false
            state.finished = true
          }
        )
      } else {
        setTimeout(() => {
          state.loading = false
        }, 3000)
      }
    }

    const setWebTkd = (gameinfo: IGameInfo) => {
      if (!gameinfo.gameName) {
        return
      }
      document.title = `${gameinfo.gameName}_${gameinfo.gameName}下载_${title}APP`
      const keyMeta = document.querySelector(
        'meta[name=keywords]'
      ) as HTMLMetaElement
      keyMeta &&
        keyMeta.setAttribute(
          'content',
          `${gameinfo.gameName},${gameinfo.gameName}手游,${gameinfo.gameName}官网,${gameinfo.gameName}下载,${gameinfo.gameName}安卓版,${gameinfo.gameName}APP`
        )
      const descMeta = document.querySelector(
        'meta[name=description]'
      ) as HTMLMetaElement
      descMeta &&
        descMeta.setAttribute(
          'content',
          `${title}APP提供${gameinfo.gameName}手游官方版下载，欢迎玩家来${title}APP下载${gameinfo.gameName}手游`
        )
    }

    onMounted(() => {
      getGameInfo(gameAlias).then(
        (res) => {
          gameinfo.value = res.data
          gameId = res.data.gameId

          setWebTkd(res.data)
          // 获取评论
          getCommentList(gameId).then((res) => {
            commentList.value = res.data.commentList
          })
          // 获取推荐游戏
          getRecommendGameList(gameId, currentPage).then(
            (res) => {
              recommendGameList.value = res.data.games
            },
            (e) => {
              console.error(e)
              state.loading = false
              state.finished = true
            }
          )

          if (versionName === 'wb') {
            // 获取小喇叭消息
            getTrumpetMessage(gameId).then(
              (res) => {
                trumpetMessageList.value = res.data.gameDynamics
              },
              (e) => {
                console.error(e)
              }
            )

            // 获取礼包
            getGiftList(gameId).then(
              (res) => {
                giftInfo.value = res.data
              },
              (e) => {
                console.error(e)
              }
            )

            // 获取代金券
            getCouponList(gameId).then(
              (res) => {
                couponInfo.value = res.data
              },
              (e) => {
                console.error(e)
              }
            )
          }
        },
        (e) => {
          console.error(e)
          state.error = true
        }
      )

      getChannelURL(packageId).then((res) => {
        state.packageUrl = res.data.url
      })
    })

    /**
     * 打开app
     */
    const handleOpenApp = (position: OpenAppEnum) => {
      const uaParser = new UaParser()
      const uaInfo = uaParser.getResult()
      // const welfareTab = position === OpenAppEnum.COUPON ? 1
      //   : position === OpenAppEnum.GIFT ? 2 : ''
      if (uaInfo.os.name === 'iOS') {
        Toast('苹果版本正在准备中，敬请期待…')
        return
      }

      if (uaInfo.os.name !== 'Android') {
        toDataURL(location.href).then((res) => {
          state.showPopup = true
          state.qrcodeDateUrl = res
        })
        return
      } else if (
        uaInfo.browser.name === 'WeChat' ||
        uaInfo.browser.name === 'Weibo'
      ) {
        state.showShare = true
        return
      }

      if (position === OpenAppEnum.DOWNLOAD) {
        webLog.report(
          'landpage',
          'landpage_download',
          gameinfo.value?.gameId + ''
        )
        window.open(state.packageUrl, '_self')
        return
      }
      let http = 'ybiybi'
      if (versionName === 'yb') {
        http = 'ybiybi'
      } else if (versionName === 'wb') {
        http = 'wanbei'
      }
      copyText(
        `${http}://goto.homePage?gameAlias=${gameAlias}`,
        undefined,
        (error, event) => {
          if (error) {
            console.log(error)
          } else {
            console.log(event)
          }
        }
      )

      const callLib = new CallApp({
        scheme: {
          protocol: http
        },
        appstore: '',
        fallback: '',
        timeout: 3000,
        logFunc: (status) => {
          if (status === 'pending') {
            Toast.loading({
              message: '请稍后...',
              forbidClick: true,
              duration: 3000
            })
            webLog.report(
              'landpage',
              'landpage_pullup',
              gameinfo.value?.gameId + ''
            )
          } else if (status === 'failure') {
            webLog.report(
              'landpage',
              'landpage_pullup_faild',
              gameinfo.value?.gameId + ''
            )
          }
        }
      })

      callLib.open({
        param: {
          gameAlias
        },
        path: 'goto.homePage',
        callback: () => {
          console.log('open app faild', position)
          switch (position) {
            case OpenAppEnum.TAGS:
            case OpenAppEnum.GAME:
              state.popupText = 'APP发现更多好玩新奇游戏'
              break
            case OpenAppEnum.COMMENT:
              state.popupText = 'APP和大家一起点评互动'
              break
            case OpenAppEnum.COUPON:
              state.popupText = '领取代金券'
              break
            case OpenAppEnum.GIFT:
              state.popupText = '领取礼包'
              break
            default:
              state.popupText = '试玩/预约该游戏'
              break
          }
          state.showPopup = true
        }
      })
    }

    return {
      gameinfo,
      commentList,
      recommendGameList,
      ...toRefs(state),
      onListLoad,
      gameAlias,
      handleOpenApp,
      versionName,
      trumpetMessageList,
      giftInfo,
      couponInfo
    }
  }
}
</script>

<style lang="scss">
@include b(app-layout) {
  height: 100vh;
}
@include b(app-content) {
  padding-top: 92px;
}

@include b(media-card) {
  overflow-x: scroll;
}

@include b(block-title) {
  margin-top: 20px;

  @include e(left) {
    display: inline-block;
    @include m(icon) {
      width: 48px;
      height: 48px;
    }
    @include m(text) {
      margin-left: 12px;
      vertical-align: top;
      line-height: 48px;
      font-size: 34px;
      color: $color-text-secondary;
      font-weight: 900;
    }
  }

  @include e(right) {
    display: inline-block;
    @include m(button) {
      @include box-center();
      box-sizing: border-box;
      @include gradient(left, #ffe046, #ffcc23, #ffc200);
      font-size: 24px;
      font-weight: bold;
      color: #272727;
      height: 64px;
      width: 160px;
      border-radius: 32px;
    }
  }
}

.line {
  background-color: #ebebeb;
  height: 1px;
}

@include b(load-more) {
  text-align: center;
  padding: 5px 0;
  background-color: #fff;
}

@include b(share) {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 100;
  padding: 0 30px;
  padding-top: 40px;

  img {
    max-width: 600px;
  }
}
</style>
