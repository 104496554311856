
import { onMounted, PropType, reactive, ref, Ref, toRefs } from 'vue'
import { IGameInfo } from '@/api/types/landingPage'
import { OpenAppEnum } from '../types'

interface IGameDetailState {
  tagCount: Ref<number>
  onClick: () => void
  expande: Ref<boolean>
  ifOver: Ref<boolean>
  expandeClick: () => void
  detailDescRef: Ref<HTMLElement | undefined>
}

export default {
  props: {
    gameinfo: {
      type: Object as PropType<IGameInfo>,
      required: true
    },
    handleOpenApp: {
      type: Function as PropType<(position: OpenAppEnum) => void>,
      required: true
    }
  },
  setup (
    props: Readonly<{
      gameinfo: IGameInfo
      handleOpenApp: (position: OpenAppEnum) => void
    }>
  ): IGameDetailState {
    const state = reactive({
      expande: false,
      ifOver: false
    })
    const tagCount = ref(0)
    const worldWidth = 14
    const padding = 20
    const margin = 10
    const offset = 55
    let totalWidth = 0
    const maxWidth = window.innerWidth - offset

    const detailDescRef = ref<HTMLElement>()

    props.gameinfo.tagVOS.forEach((tag) => {
      totalWidth += tag.labelName.length * worldWidth + margin + padding
      if (maxWidth < totalWidth) {
        return false
      }
      tagCount.value += 1
    })

    const onClick = () => {
      props.handleOpenApp(OpenAppEnum.TAGS)
    }

    const expandeClick = () => {
      state.expande = !state.expande
    }

    onMounted(() => {
      if (detailDescRef.value) {
        state.ifOver =
          detailDescRef.value.offsetHeight < detailDescRef.value.scrollHeight
      }
    })

    return {
      tagCount,
      onClick,
      ...toRefs(state),
      expandeClick,
      detailDescRef
    }
  }
}
