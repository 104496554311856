<template>
    <yb-card mt20 class="gameinfo-card">
        <div class="gameinfo">
            <div class="gameinfo__logo">
                <img :src="gameinfo.gameIcon" alt />
            </div>
            <h2 class="gameinfo__name">{{gameinfo.gameName}}</h2>
            <div class="gameinfo__detail">
                <p
                    class="gameinfo__detail--factory"
                    v-if="gameinfo.gameDeveloperName"
                >厂商: {{gameinfo.gameDeveloperName}}</p>
                <p
                    class="gameinfo__detail--reserve"
                >&nbsp;{{(gameinfo.pkgSize / 1024 / 1024).toFixed(2)}} MB</p>
                <div class="gameinfo__start">
                    <yb-rate :count="gameinfo.score" v-if="gameinfo.score > 0">
                        <span>{{parseFloat(gameinfo.score).toFixed(1)}}</span>
                    </yb-rate>
                </div>
            </div>
        </div>
        <div class="gameWelfare">
            <div class="gameWelfare__tags" v-if="welfareTags.length > 0">
                <template v-for="(tag, index) in welfareTags" :key="tag.content + index">
                    <div class="gameWelfare__tags--tag" :style="tagDynamicStyle(tag.type)">
                        <span>
                            <yb-svg-vip v-if="tag.type === 0" :width="'14px'" :height="'14px'" />
                            <yb-svg-pay v-if="tag.type === 1" :width="'14px'" :height="'14px'" />
                            <yb-svg-rebate v-if="tag.type === 2" :width="'14px'" :height="'14px'" />
                            <yb-svg-other v-if="tag.type === 3" :width="'14px'" :height="'14px'" />
                        </span>
                        <span>{{tag.content}}</span>
                    </div>
                </template>
            </div>
        </div>
        <div class="gameMessage" v-if="gameDynamics.length > 0">
            <van-notice-bar
                class="gameMessage__notice"
                :left-icon="noticeImg"
                :scrollable="false"
                color="#FFA100"
                background="#FFFFFF"
            >
                <van-swipe
                    vertical
                    class="gameMessage__notice--swipe"
                    :autoplay="3000"
                    :show-indicators="false"
                >
                    <van-swipe-item :key="item + index" v-for="(item,index) in gameDynamics">
                        <div class="van-ellipsis">{{item}}</div>
                    </van-swipe-item>
                </van-swipe>
            </van-notice-bar>
        </div>
    </yb-card>
</template>

<script lang="ts">
import YbRate from '@/components/Rate.vue'
import { computed, PropType, ref, Ref } from 'vue'
import { IGameInfo } from '@/api/types/landingPage'
import { NoticeBar, Swipe, SwipeItem } from 'vant'
import noticeImg from '@/assets/notice.png'

interface IWelfareTags {
  type: number
  content: string
}

interface IWbGameInfoState {
  welfareTags: Ref<IWelfareTags[]>
  tagCount: Ref<number>
  tagDynamicStyle: (type: number) => void
  noticeImg: string
  gameDynamics: Ref<string[] | string>
}
export default {
  props: {
    gameinfo: {
      type: Object as PropType<IGameInfo>,
      required: true
    },
    trumpetMessageList: {
      type: [Array, String] as PropType<string[] | string>,
      required: true
    }
  },
  components: {
    [NoticeBar.name]: NoticeBar,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    YbRate
  },
  setup (
    props: Readonly<{
      gameinfo: IGameInfo
      trumpetMessageList: string[] | string
    }>
  ): IWbGameInfoState {
    const tagCount = ref(0)

    const welfareTags = computed<IWelfareTags[]>(() => {
      if (props.gameinfo.welfareTags) {
        const Tags = JSON.parse(props.gameinfo.welfareTags)
        return Tags
      }
      return []
    })

    const gameDynamics = computed<string[] | string>(() => {
      if (props.trumpetMessageList.length > 0) {
        return props.trumpetMessageList
      }
      return []
    })

    const tagDynamicStyle = (type: number) => {
      const style = {
        background: 'rgba(255, 252, 241, 1)',
        color: '#F8CB62',
        border: '1px solid #FFF2C9'
      }
      switch (type) {
        case 0:
          style.background = 'rgba(255, 245, 243, 1)'
          style.color = '#FF7D61'
          style.border = '1px solid #FFE0DA'
          break
        case 1:
          style.background = 'rgba(255, 248, 241, 1)'
          style.color = '#FFA857'
          style.border = '1px solid #FFE3C9'
          break
        case 2:
          style.background = 'rgba(241, 255, 247, 1)'
          style.color = '#60D390'
          style.border = '1px solid #C9F4DA'
          break

        default:
          break
      }
      return style
    }

    return {
      tagCount,
      welfareTags,
      tagDynamicStyle,
      noticeImg,
      gameDynamics
    }
  }
}
</script>

<style lang="scss" scoped>
.gameinfo-card {
  padding-bottom: 40px;
}

@include b(gameinfo) {
  position: relative;

  @include e(logo) {
    position: absolute;
    width: 180px;
    height: 180px;
    margin-left: 30px;
    box-sizing: border-box;
    border-radius: 40px;
    overflow: hidden;
  }

  @include e(name) {
    margin: 0;
    padding-left: 225px;
    box-sizing: border-box;
    @include box-clamp(1);
    font-size: 38px;
    color: $color-text-secondary;
    font-weight: bold;
  }

  @include e(detail) {
    padding-left: 225px;
    // background-color: rgba(255, 196, 0, 0.1);
    border-radius: 20px;
    margin-top: 10px;
    font-size: 22px;
    color: $color-text-primary;
    padding-bottom: 30px;
    min-height: 134px;

    p {
      padding-top: 14px;
      font-size: 22px;
      color: $color-text-primary;
    }
  }

  @include e(start) {
    margin-top: 14px;

    span {
      vertical-align: top;
      margin-left: 10px;
      display: inline-block;
      font-size: 28px;
      color: #ffa100;
      margin-top: -1px;
    }

    p {
      padding: 0;
      font-size: 22px;
      color: #ffa100;
    }
  }
}

@include b(gameWelfare) {
  @include e(tags) {
    margin-left: 20px;
    display: flex;
    flex-wrap: wrap;
    @include m(tag) {
      height: 40px;
      background-color: rgba(255, 196, 0, 0.2);
      color: #ffa100;
      font-size: 22px;
      padding: 0px 8px;
      border-radius: 12px;
      margin: 5px;
      display: flex;
      align-items: center;

      span:first-child {
        margin-right: 2px;
        svg {
          margin-top: 1px;
        }
      }

      &::after {
        content: '';
        flex: auto;
      }
    }
  }
}

@include b(gameMessage) {
  margin-top: 25px;
  @include e(notice) {
    height: 40px;

    @include m(swipe) {
      height: 40px;
      line-height: 46px;
      font-size: 24px;
    }
  }
}
</style>
