<template>
  <yb-card mt20>
    <div class="block-title">
      <div class="block-title__left">
        <yb-svg-tag :width="'24px'" :height="'24px'" />
        <span class="block-title__left--text">游戏评价</span>
      </div>
      <div class="block-title__right" fr>
        <span class="block-title__right--button" @click="onClick">我也来评论</span>
      </div>
    </div>
    <template
      v-for="(item, index) in commentList"
      :key="item.id"
    >
      <div class="game-comment">
        <div class="top">
          <div :class="{
            'top__avatar': true,
            'pink': item.authorData.sex === 0
          }">
            <img class="top__avatar--img" :src="item.authorData.avatarUrl" alt="" v-if="item.authorData.avatarUrl">
            <img class="top__avatar--img" src='../../../assets/defualt_avather.png' v-else>
          </div>
          <div class="top__msg">
            <p class="top__msg--name">{{item.authorData.nickname}}</p>
            <yb-rate :count="item.score">
              <!-- <span class="top__msg--text">推荐了游戏</span> -->
            </yb-rate>
          </div>
        </div>
        <div :class="{
          'center comment-center': true,
          'ellipsis': list[index] === 1
        }">
          {{item.content}}
        </div>
        <div v-if="list[index] !== 0" class="show-more" @click="list[index] = list[index] === 1 ? 2 : 1">{{list[index] === 1 ? '显示全部' : '收起'}}</div>
        <div class="bottom">
          <yb-row>
            <yb-col :span="12">{{item.createTime}}</yb-col>
            <yb-col :span="12">
              <div class="bottom__right" fr>
                <yb-svg-like :colors="['#ABABAB']" :width="'12px'" :height="'12px'" /> <span class="bottom__right--text">{{item.likeCount}}</span>
                <yb-svg-message ml10 :colors="['#ABABAB']" :width="'12px'" :height="'12px'" /> <span class="bottom__right--text">{{item.ryReplyCount}}</span>
              </div>
            </yb-col>
          </yb-row>
        </div>
      </div>
      <div class="line" mv30 v-if="index + 1 < commentList.length"></div>
    </template>

    <div class="go-box" mt20 mb10 @click="onClick">
      打开{{openAppText}}App，查看全部精彩点评
      <yb-svg-arrow class="go-box__arrow" :colors="['#FFA100']" width="12px" height="12px" />
    </div>
  </yb-card>
</template>

<script lang="ts">
import YbRate from '@/components/Rate.vue'
import { PropType, onMounted, ref, Ref } from 'vue'
import { ICommentInfo } from '@/api/types/landingPage'
import { OpenAppEnum } from '../types'
import detailsConfig from '@/config/details'

interface ICommentState {
  formatDate: (date: string) => string;
  onClick: () => void;
  list: Ref<number[]>; // 0 没有超出 1 超出未显示全部 2 已显示全部
  openAppText: Ref<string>;
}
const { title } = detailsConfig

export default {
  components: {
    YbRate
  },
  props: {
    commentList: {
      type: Array as PropType<ICommentInfo[]>,
      required: true
    },
    handleOpenApp: {
      type: Function as PropType<(position: OpenAppEnum) => void>,
      required: true
    }
  },
  setup (
    props: Readonly<{
      commentList: ICommentInfo[];
      handleOpenApp: (position: OpenAppEnum) => void
    }>
  ): ICommentState {
    const list = ref<number[]>([])
    const openAppText = ref<string>(title)

    props.commentList.map(() => {
      list.value.push(0)
    })

    const formatDate = (date: string) => {
      return date
    }

    const onClick = () => {
      props.handleOpenApp(OpenAppEnum.COMMENT)
    }

    onMounted(() => {
      const contentDoms = document.querySelectorAll('.comment-center')
      contentDoms.forEach((dom, index) => {
        if ((dom as HTMLDivElement).offsetHeight > 60) {
          list.value[index] = 1
        }
      })
    })

    return {
      formatDate,
      onClick,
      list,
      openAppText
    }
  }
}
</script>

<style lang="scss" scoped>
@include b(top) {
  margin-top: 40px;
  position: relative;

  @include e(avatar) {
    width: 60px;
    height: 60px;
    border: 2px solid #31B2FF;
    border-radius: 100%;
    overflow: hidden;
    display: inline-block;
    position: absolute;

    &.pink {
      border-color: #FF648B;
    }
  }

  @include e(msg) {
    vertical-align: top;
    padding-left: 85px;

    @include m(name) {
      color: $color-text-secondary;
      font-weight: bold;
      font-size: 28px;
      @include box-clamp(1);
    }

    @include m(text) {
      color: #ABABAB;
      font-size: 22px;
      vertical-align: top;
      margin-top: 3px;
      display: inline-block;
      margin-left: 16px;;
    }
  }
}

@include b(center) {
  color: $color-text-primary;
  font-size: 28px;
  line-height: 1.4;
  margin-top: 20px;

  &.ellipsis {
    @include box-clamp(3);
  }
}

@include b(show-more) {
  cursor: pointer;
  text-align: right;
  font-size: 24px;
  color: #FFA100;
  margin-top: 3px;
}

@include b(bottom) {
  margin-top: 20px;
  font-size: 22px;
  color: #ABABAB;

  @include e(right) {
    @include m(text) {
      vertical-align: top;
      display: inline-block;
      margin-top: 2px;
      margin-left: 2px;
    }
  }
}

@include b(go-box) {
  background-color: rgba(255, 196, 0, 0.2);
  border: 1px solid #FFA100;
  border-radius: 50px;
  text-align: center;
  font-size: 28px;
  color: #FFA100;
  height: 64px;
  line-height: 64px;
  position: relative;

  @include e(arrow) {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
  }
}
</style>
