
import { IRecommendGame } from '@/api/types/landingPage'
import { PropType } from 'vue'
import { OpenAppEnum } from '../types'
import { Image as VanImage } from 'vant'

interface IOthersState {
  onClick: () => void;
}

export default {
  name: 'other-game',
  components: {
    [VanImage.name]: VanImage
  },
  props: {
    gameList: {
      type: Array as PropType<IRecommendGame[]>,
      required: true
    },
    handleOpenApp: {
      type: Function as PropType<(position: OpenAppEnum) => void>,
      required: true
    }
  },
  setup (
    props: Readonly<{
      gameList: IRecommendGame[];
      handleOpenApp: (position: OpenAppEnum) => void;
    }>
  ): IOthersState {
    const onClick = () => {
      props.handleOpenApp(OpenAppEnum.GAME)
    }

    return {
      onClick
    }
  }
}
