
import { PropType, ref, Ref, onMounted } from 'vue'
import { IGameInfo } from '@/api/types/landingPage'
import { ImagePreview, Popup, Icon } from 'vant'
import 'vant/lib/image-preview/style'
import 'vant/lib/icon/style'
import videoBgImg from '../../../assets/video_bg.png'

type IPosition = 'horizontal' | 'vertical'

interface IImage {
  url: string;
  position: IPosition;
}

interface IMediaState {
  handlePreviewImage: (index: number) => void;
  showPopup: Ref<boolean>;
  isAllVertical: Ref<boolean>;
  imgs: Ref<IImage[]>;
  hadVideo: Ref<boolean>;
}

export default {
  props: {
    gameinfo: {
      type: Object as PropType<IGameInfo>,
      required: true
    }
  },
  components: {
    [Popup.name]: Popup,
    [Icon.name]: Icon
  },
  setup (
    props: Readonly<{
      gameinfo: IGameInfo;
    }>
  ): IMediaState {
    const showPopup = ref(false)
    const hadVideo = ref(false)
    const isAllVertical = ref(true)
    const imgs = ref<IImage[]>([])
    let imgList: string[] = []

    const handlePreviewImage = (index: number): void => {
      const arr = [...imgList]
      if (hadVideo.value) {
        arr.shift()
        index = index - 1
      }
      ImagePreview({
        images: arr,
        closeable: true,
        startPosition: index
      })
    }

    const getImgPosition = (arr: string[]) => {
      arr.forEach((img, index) => {
        const image = new Image()
        image.crossOrigin = 'anonymous'
        image.onload = () => {
          if (image.width >= image.height) {
            isAllVertical.value = false
          }
          imgs.value.splice(index, 0, {
            url: img,
            position: image.width >= image.height ? 'horizontal' : 'vertical'
          })
        }
        image.onerror = (e) => {
          console.error(e)
        }
        image.src = img
      })
    }

    onMounted(() => {
      imgList = []
      if (props.gameinfo.videoData && props.gameinfo.videoData.videoUrl) {
        hadVideo.value = true

        if (props.gameinfo.videoData.imgUrl) {
          imgList.push(props.gameinfo.videoData.imgUrl)
        } else if (props.gameinfo.coverUrl) {
          imgList.push(props.gameinfo.coverUrl)
        } else {
          imgList.push(videoBgImg)
        }
      }

      props.gameinfo.pics.forEach(pic => {
        imgList.push(pic.imgUrl)
      })

      getImgPosition(imgList)
    })

    return {
      handlePreviewImage,
      showPopup,
      isAllVertical,
      imgs,
      hadVideo
    }
  }
}
