
import { defineComponent } from 'vue'
import Promote from './index.vue'

export default defineComponent({
  name: 'App',
  components: {
    Promote
  }
})
