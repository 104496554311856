
import YbRate from '@/components/Rate.vue'
import { PropType, onMounted, ref, Ref } from 'vue'
import { ICommentInfo } from '@/api/types/landingPage'
import { OpenAppEnum } from '../types'
import detailsConfig from '@/config/details'

interface ICommentState {
  formatDate: (date: string) => string;
  onClick: () => void;
  list: Ref<number[]>; // 0 没有超出 1 超出未显示全部 2 已显示全部
  openAppText: Ref<string>;
}
const { title } = detailsConfig

export default {
  components: {
    YbRate
  },
  props: {
    commentList: {
      type: Array as PropType<ICommentInfo[]>,
      required: true
    },
    handleOpenApp: {
      type: Function as PropType<(position: OpenAppEnum) => void>,
      required: true
    }
  },
  setup (
    props: Readonly<{
      commentList: ICommentInfo[];
      handleOpenApp: (position: OpenAppEnum) => void
    }>
  ): ICommentState {
    const list = ref<number[]>([])
    const openAppText = ref<string>(title)

    props.commentList.map(() => {
      list.value.push(0)
    })

    const formatDate = (date: string) => {
      return date
    }

    const onClick = () => {
      props.handleOpenApp(OpenAppEnum.COMMENT)
    }

    onMounted(() => {
      const contentDoms = document.querySelectorAll('.comment-center')
      contentDoms.forEach((dom, index) => {
        if ((dom as HTMLDivElement).offsetHeight > 60) {
          list.value[index] = 1
        }
      })
    })

    return {
      formatDate,
      onClick,
      list,
      openAppText
    }
  }
}
