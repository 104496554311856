<template>
    <yb-card mt20 class="welfareInfo-card" v-if="gameinfo.welfareInfo">
        <div class="block-title">
            <div class="block-title__left">
                <yb-svg-welfare-party :width="'24px'" :height="'24px'" />
                <span class="block-title__left--text">福利活动</span>
            </div>
        </div>
        <div class="welfare-info">
            <div :class="{'welfare-info__content--hidden': !expande}" ref="welfareContentRef">
                <div v-html="gameinfo.welfareInfo"></div>
            </div>
            <div
                class="welfare-info__moreBtn"
                v-if="ifOver"
                @click="expandeClick"
            >{{expande ? '收起' : '展开'}}</div>
        </div>
    </yb-card>
</template>

<script lang='ts'>
import { IGameInfo } from '@/api/types/landingPage'
import { onMounted, PropType, reactive, ref, Ref, toRefs } from 'vue'

interface IWbWelfareInfoState {
  welfareContentRef: Ref<HTMLElement | undefined>
  expande: Ref<boolean>
  ifOver: Ref<boolean>
  expandeClick: () => void
}

export default {
  props: {
    gameinfo: {
      type: Object as PropType<IGameInfo>,
      required: true
    }
  },
  setup (): IWbWelfareInfoState {
    const state = reactive({
      expande: false,
      ifOver: false
    })
    const welfareContentRef = ref<HTMLElement>()

    const expandeClick = () => {
      state.expande = !state.expande
    }

    onMounted(() => {
      if (welfareContentRef.value) {
        state.ifOver =
          welfareContentRef.value.offsetHeight <
          welfareContentRef.value.scrollHeight
      }
    })

    return {
      ...toRefs(state),
      expandeClick,
      welfareContentRef
    }
  }
}
</script>
<style lang='scss' scoped>
.welfareInfo-card {
  padding-bottom: 40px;
}
@include b(welfare-info) {
  margin-top: 14px;
  padding: 10px 20px;

  @include e(content) {
    @include m(hidden) {
      @include ellipsis(16);
    }
  }

  @include e(moreBtn) {
    cursor: pointer;
    text-align: end;
    font-size: 28px;
    font-weight: 400;
    color: #ffa100;
    margin-top: 5px;
  }
}
</style>
