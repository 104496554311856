import PMedia from './media.vue'
import PGameinfo from './gameinfo.vue'
import PGameDetail from './gamedetail.vue'
import PComment from './comment.vue'
import POthers from './others.vue'

export default {
  PMedia,
  PGameinfo,
  PGameDetail,
  PComment,
  POthers
}
