<template>
  <yb-card mt20 class="gameinfo-card">
    <div class="gameinfo">
      <div class="gameinfo__logo">
        <img :src="gameinfo.gameIcon" alt="">
      </div>
      <h2 class="gameinfo__name">{{gameinfo.gameName}}</h2>
      <div class="gameinfo__detail">
        <p class="gameinfo__detail--factory" v-if="gameinfo.gameDeveloperName">厂商: {{gameinfo.gameDeveloperName}}</p>
        <p class="gameinfo__detail--reserve">{{gameinfo.playCount}}人{{gameinfo.reserve ? '预约' : '在玩'}}&nbsp;&nbsp;&nbsp;{{(gameinfo.pkgSize / 1024 / 1024).toFixed(2)}} MB</p>
        <div class="gameinfo__start">
          <yb-rate :count="gameinfo.score" v-if="gameinfo.score > 0">
            <span>{{parseFloat(gameinfo.score).toFixed(1)}}</span>
          </yb-rate>
        </div>
      </div>
    </div>
  </yb-card>
</template>

<script lang="ts">
import YbRate from '@/components/Rate.vue'
import { PropType } from 'vue'
import { IGameInfo } from '@/api/types/landingPage'

export default {
  props: {
    gameinfo: {
      type: Object as PropType<IGameInfo>,
      required: true
    }
  },
  components: {
    YbRate
  }
}
</script>

<style lang="scss" scoped>
.gameinfo-card {
  padding-bottom: 40px;
}

@include b(gameinfo) {
  position: relative;

  @include e(logo) {
    position: absolute;
    width: 180px;
    height: 180px;
    margin-left: 30px;
    box-sizing: border-box;
    border-radius: 40px;
    overflow: hidden;
  }

  @include e(name) {
    margin: 0;
    padding-left: 225px;
    box-sizing: border-box;
    @include box-clamp(1);
    font-size: 38px;
    color: $color-text-secondary;
    font-weight: bold;
  }

  @include e(detail) {
    padding-left: 225px;
    background-color: rgba(255, 196, 0, 0.1);
    border-radius: 20px;
    margin-top: 10px;
    font-size: 22px;
    color: $color-text-primary;
    padding-bottom: 30px;
    min-height: 134px;

    p {
      padding-top: 14px;
      font-size: 22px;
      color: $color-text-primary;
    }
  }

  @include e(start) {
    margin-top: 14px;

    span {
      vertical-align: top;
      margin-left: 10px;
      display: inline-block;
      font-size: 28px;
      color: #FFA100;
      margin-top: -1px;
    }

    p {
      padding: 0;
      font-size: 22px;
      color: #FFA100;
    }
  }
}
</style>
