
import { reactive, Ref, toRefs } from 'vue'

interface IRateState {
  stars: Ref<number>;
  halfStar: Ref<boolean>;
}

export default {
  props: {
    count: {
      type: Number,
      required: true
    },
    total: {
      type: Number,
      default: 10
    }
  },
  setup (props: Readonly<{ total: number; count: number } >): IRateState {
    const state = reactive({
      stars: 0,
      halfStar: false
    })
    const scale = props.total / 5
    const realCount = props.count / scale
    state.halfStar = realCount % 2 !== 0 && props.count !== props.total

    state.stars = parseInt(realCount + '')

    return {
      ...toRefs(state)
    }
  }
}
